var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4",staticStyle:{"background-color":"#dcdcdc"}},[_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v("Pending Changes")])],1),_vm._l((_vm.changes),function(ref){
var _id = ref._id;
var action = ref.action;
var status = ref.status;
var course = ref.course;
var approvals = ref.approvals;
return _c('change',{key:'alert-' + _id,attrs:{"action":action,"status":status,"course":course,"approvals":approvals},on:{"cancel":function($event){return _vm.cancelChange(_id)}}})})],2),(_vm.completed.length + _vm.cancelled.length > 0)?_c('v-expansion-panels',{staticClass:"mt-4"},[(_vm.completed.length > 0)?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Completed Changes")]),_c('v-expansion-panel-content',_vm._l((_vm.completed),function(ref){
var _id = ref._id;
var action = ref.action;
var cancelled = ref.cancelled;
var course = ref.course;
return _c('change',{key:'alert-' + _id,attrs:{"action":action,"status":cancelled ? 'cancelled' : 'completed',"course":course}})}),1)],1):_vm._e(),(_vm.cancelled.length > 0)?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Cancelled Changes")]),_c('v-expansion-panel-content',_vm._l((_vm.cancelled),function(ref){
var _id = ref._id;
var action = ref.action;
var cancelled = ref.cancelled;
var course = ref.course;
return _c('change',{key:'alert-' + _id,attrs:{"action":action,"status":cancelled ? 'cancelled' : 'completed',"course":course}})}),1)],1):_vm._e()],1):_vm._e()],1),_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('v-card',{staticStyle:{"background-color":"#eeeeee"}},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v("Timeline")]),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-comment-plus")])],1)]}}]),model:{value:(_vm.addCommentDialog),callback:function ($$v) {_vm.addCommentDialog=$$v},expression:"addCommentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Comment")]),_c('v-card-text',[_c('p',[_vm._v("Add a comment/message for the instructors, advisors, and/or the Records office.")]),_c('v-textarea',{attrs:{"label":"Comment/Message","outlined":"","hide-details":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addCommentDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":_vm.comment === '',"color":"success","text":""},on:{"click":_vm.addComment}},[_vm._v("Add Comment")])],1)],1)],1)],1),_c('v-card-text',[_c('v-timeline',{attrs:{"dense":""}},_vm._l((_vm.timeline),function(message){return _c('v-timeline-item',{key:'message-' + message._id,class:!(message.icon) && message.pidm !== _vm.user.pidm ? 'reverse-icon' : '',attrs:{"color":message.color || 'info'},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v(_vm._s(message.icon || 'fal fa-comment'))])]},proxy:true}],null,true)},[_c('v-card',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){message.expanded = !message.expanded}}},[_c('v-card-text',{style:(message.expanded ? 'padding-bottom:10px' : 'overflow:hidden;text-overflow:ellipsis;white-space:nowrap;padding-bottom:10px;'),domProps:{"innerHTML":_vm._s(message.text)}}),_c('v-card-text',{staticStyle:{"font-size":".8em","opacity":".7","padding-top":"0"}},[_vm._v(_vm._s(message.name)+" -- "+_vm._s(_vm.stringFormatDate(message.date)))])],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }